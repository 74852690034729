import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { debounceTime, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { CustomerInvoicingService } from '../../services/invoicing.service';
import { FilterInvoicingSidebarComponent } from './filter-sidebar/filter-invoicing-sidebar.component';
export interface Invoicing {
  invoiceNo: string;
  title: string;
  type: string;
  account: string;
  costCenter: string;
  month: string;
  amount: string;
}
@Component({
  selector: 'app-invoicing-list',
  templateUrl: './invoicing.component.html',
})
export class CustomerInvoicingComponent implements OnInit {
  loading: boolean = false;
  invoicingList: any[] = [];
  totalRecords: number = 0;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  costModal: string = '';
  projectName: string[] = [];
  form!: FormGroup;
  element: any;
  invoiceID!: string;
  projectList: any[] = [];
  costCenterList: any[] = [];
  projectChecked: { [projectId: string]: boolean } = {};
  costCenterChecked: { [cost_centerID: string]: boolean } = {};
  filteredInvoices: any[] = [];
  invoiceDate: any;
  lastSortBy: string = '';
  accountID: string = '';
  canManageResources: boolean = false;
  isFilterApplied: boolean = false;
  selectedDates: string[] = [];
  selectedProjects: string[] = [];
  selectedCostCenters: string[] = [];
  selectedStatus: string[] = [];

  @ViewChild('projectSelect') projectSelect!: MatSelect;
  @ViewChild('costCentreSelect') costCentreSelect!: MatSelect;

  @ViewChild('filter') filterComponent!: FilterInvoicingSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  constructor(
    private invoicingService: CustomerInvoicingService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) { }

  displayedColumns: string[] = [
    'invoiceNo',
    'title',
    'type',
    // 'account',
    'costCenter',
    'month',
    'amount',
  ];
  dataSource = new MatTableDataSource<Invoicing>([]);

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );

    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchInvoicing();
  }

  getInvoicingList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
  ): void {
    this.loading = true;
    const statusName = "sent"
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        '',
        '',
        '',
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  retrieveDataForDateRange(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    invoiceDate: string
  ) {
    this.loading = true;
    const stausName = "sent"
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        invoiceDate,
        '',
        '',
        stausName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingListByProject(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[] = []
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    const stausName = "sent";
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        '',
        projectId,
        '',
        stausName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoiceListByCostCenter(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[] = []
  ): void {
    this.loading = true;
    const costCenterIds = selectedCostCenters.join('&cost_centerID=');
    const statusName = "sent"
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        '',
        '',
        costCenterIds,
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoiceListByStatus(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedStatus: string[] = []
  ): void {
    this.loading = true;
    const statusName = selectedStatus.join('&invoiceStatus=');
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        '',
        '',
        '',
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedDates: string[],
    selectedProjects: string[],
    selectedCostCenters: string[],
  ): void {
    this.loading = true;
    const dates = selectedDates.join('&invoiceDate=');
    const projectId = selectedProjects.join('&projectId=');
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    const statusName = "sent"
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        dates,
        projectId,
        costCenterID,
        statusName
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.invoicingList = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Invoicing List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any) {
    this.selectedDates = filters.selectedDates || [];
    this.selectedProjects = filters.selectedProjects || [];
    this.selectedCostCenters = filters.selectedCostCenters || [];
    if (this.selectedProjects.length > 0 && this.selectedCostCenters.length > 0) {
      this.getInvoicingListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedDates,
        this.selectedProjects,
        this.selectedCostCenters
      );
    } else if (this.selectedDates.length > 0) {
      const selectedDatesString = this.selectedDates.join(',');
      this.retrieveDataForDateRange(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedDatesString
      );
    } else if (this.selectedCostCenters.length > 0) {
      this.getInvoiceListByCostCenter(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedCostCenters
      );
    } else if (this.selectedProjects.length > 0) {
      this.getInvoicingListByProject(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.selectedProjects
      );
    } else {
      this.getInvoicingList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';
    const dates = this.selectedDates.join('&invoiceDate=');
    const projectId = this.selectedProjects.join('&projectId=');
    const costCenterID = this.selectedCostCenters.join('&cost_centerID=');
    const statusName = "sent"

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;
    this.invoicingService
      .getInvoiceList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.accountID,
        dates,
        projectId,
        costCenterID,
        statusName
      )
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.invoicingList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Invoice List request:', error);
        },
      });
  }

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }


  handleFilterChange(selectedOption: string) {
    let startDate: Date;
    this.loading = true;

    switch (selectedOption) {
      case 'one':
        this.invoiceDate = '';
        break;
      case 'two':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 3);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'three':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 6);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'four':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 9);
        this.invoiceDate = this.formatDate(startDate);
        break;
      case 'five':
        this.invoiceDate = '2023-01-01';
        break;
      default:
        break;
    }
    this.retrieveDataForDateRange(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy,
      this.invoiceDate
    );
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  searchInvoicing(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getInvoicingList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }
}
